export const topProgramData = [
  {
    id: 1,
    url: "/courses/data-analytics-bootcamp",
    title: "Data Analytics",
  },
  {
    id: 2,
    url: "/courses/design-bootcamp",
    title: "Design",
  },
  {
    id: 3,
    url: "/courses/product-management-bootcamp",
    title: "Product Accelerator",
  },
  {
    id: 4,
    url: "/courses/tech-sales-bootcamp",
    title: "Tech Sales Bootcamp",
  },
];

export const topProgramEuropeData = [
  {
    id: 1,
    url: "/courses/data-science-bootcamp",
    title: "Data Bootcamp",
  },
  {
    id: 2,
    url: "/courses/product-management-bootcamp",
    title: "Product Bootcamp",
  },
  {
    id: 3,
    url: "/courses/cloud-bootcamp",
    title: "Cloud Bootcamp",
  },
  {
    id: 4,
    url: "/courses/business-analysis-bootcamp",
    title: "Business Analysis",
  },
];
