import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/svg/logo/utivacolored.svg";
import FacebookIcon from "../svgs/FacebookIcon";
import TwitterIcon from "../svgs/TwitterIcon";
import InstagramIcon from "../svgs/InstagramIcon";
import LinkedInIcon from "../svgs/LinkedInIcon";
import YoutubeIcon from "../svgs/YoutubeIcon";
import FixedWhatsappLink from "./FixedWhatsappLink";

const SocialMedia = () => {
  const data = [
    {
      id: 1,
      url: "https://www.facebook.com/utiva.io/",
      icon: <FacebookIcon />,
    },

    {
      id: 2,
      url: "https://www.instagram.com/utiva.io/",
      icon: <InstagramIcon />,
    },
    {
      id: 3,
      url: "https://www.linkedin.com/company/27145142/",
      icon: <LinkedInIcon />,
    },
    {
      id: 4,
      url: "https://www.youtube.com/utiva",
      icon: <YoutubeIcon />,
    },

    {
      id: 5,
      url: "https://twitter.com/utiva_io",
      icon: <TwitterIcon />,
    },
  ];
  return (
    <div className="mt-6 lg:mt-8 flex flex-row items-center space-x-0">
      {data?.map((datum) => (
        <a key={datum?.id} href={datum?.url} className="pr-3 mb-2">
          {datum?.icon}
        </a>
      ))}
    </div>
  );
};

const Column = ({ title, links = [], isTargetLink = false }) => {
  return (
    <div className=" mb-10 relative w-3/6 md:w-auto">
      <h3 className="text-sm md:text-sm-15 mb-7 text-default font-bold">
        {title}
      </h3>

      <ul className="space-y-4 text-default text-xs md:text-sm">
        {links.map((link) => (
          <li key={link.id} className="hover:text-secondary transition-all ">
            {isTargetLink || link?.url?.includes("http") ? (
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                {link.linkName}
              </a>
            ) : (
              <Link to={link.url}>{link.linkName}</Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Footer = () => {
  const columnData1 = [
    {
      id: "i1",
      url: "/courses/data-analytics-bootcamp",
      linkName: "Data Analytics Bootcamp",
    },
    {
      id: "i6",
      url: "/courses/data-science-bootcamp",
      linkName: "Data Science Bootcamp",
    },
    {
      id: "i4",
      url: "/courses/product-management-bootcamp",
      linkName: "Product Accelerator",
    },
    {
      id: "i5",
      url: "/courses/design-bootcamp",
      linkName: "Design Bootcamp",
    },
    {
      id: "i7",
      url: "/courses/tech-sales-bootcamp",
      linkName: "Tech Sales Bootcamp",
    },
  ];

  const columnData2 = [
    {
      id: "j1",
      url: "/get-started",
      linkName: "Individual",
    },
    {
      id: "j2",
      url: "/business",
      linkName: "Business",
    },
    {
      id: "j3",
      url: "https://impact.utiva.io",
      linkName: "Impact",
    },
    {
      id: "j4",
      url: "https://utivakids.com/",
      linkName: "Utiva Kids",
    },
    {
      id: "j5",
      url: "/nucleus",
      linkName: "The Nucleus",
    },
    {
      id: "j6",
      url: "/ubuntu",
      linkName: "Ubuntu",
    },
    {
      id: "j7",
      url: "/hire",
      linkName: "Utiva Hire",
    },
    {
      id: "j9",
      url: "/migrate",
      linkName: "Utiva Migrate",
    },
  ];

  const columnData3 = [
    {
      id: "k1",
      url: "/school/data-school",
      linkName: "Data School",
    },
    {
      id: "k2",
      url: "/school/design-school",
      linkName: "Design School",
    },
    {
      id: "k3",
      url: "/school/product-school",
      linkName: "Product School",
    },
    {
      id: "k4",
      url: "/school/programming-school",
      linkName: "Programming School",
    },
    {
      id: "k5",
      url: "/school/cloud-school",
      linkName: "Cloud Computing School",
    },
    {
      id: "k7",
      url: "/school/blockchain-developer-school",
      linkName: "Blockchain Dev. School",
    },
    {
      id: "k6",
      url: "/school/product-marketing-school",
      linkName: "Marketing School",
    },
  ];

  const columnData4 = [
    {
      id: "l1",
      url: "/about-us",
      linkName: "About us",
    },
    {
      id: "l2",
      url: "/champions",
      linkName: "Learning Champions",
    },
    {
      id: "l3",
      url: "/partners",
      linkName: "Our Partners",
    },
    {
      id: "l4",
      url: "/be-a-trainer",
      linkName: "Become a Trainer",
    },
    {
      id: "l5",
      url: "/alumni",
      linkName: "Alumni",
    },
    {
      id: "l6",
      url: "/careers",
      linkName: "Careers",
    },
    {
      id: "l7",
      url: "/ubn",
      linkName: "Business Network",
    },
    {
      id: "l8",
      url: "/podcast",
      linkName: "Podcast",
    },
  ];

  const columnData5 = [
    {
      id: "m1",
      url: "/blog",
      linkName: "Blog",
    },
    {
      id: "m2",
      url: "/faqs",
      linkName: "FAQs",
    },
    {
      id: "m8",
      url: "/events",
      linkName: "Events",
    },
    {
      id: "m3",
      url: "/stories",
      linkName: "Success Stories",
    },
    {
      id: "m4",
      url: "/mc",
      linkName: "Masterclass",
    },
    {
      id: "m5",
      url: "/corporate-training",
      linkName: "Consulting Practice",
    },
    {
      id: "m6",
      url: "/scholarship",
      linkName: "Scholarship",
    },
  ];

  const columnData6 = [
    {
      id: "n1",
      url: "/terms",
      linkName: "Terms and Conditions",
    },
    {
      id: "n2",
      url: "/refund-policy",
      linkName: "Refund Policy",
    },
    {
      id: "n3",
      url: "/privacy-policy",
      linkName: "Privacy Policy",
    },
  ];

  const columnData7 = [
    {
      id: "p1",
      url: "mailto:info@utiva.io",
      linkName: (
        <p className="flex items-center">
          <svg
            width="13"
            height="11"
            viewBox="0 0 13 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7603 0.946777H2.01025C1.38916 0.946777 0.885254 1.4894 0.885254 2.1582V9.42676C0.885254 10.0956 1.38916 10.6382 2.01025 10.6382H11.7603C12.3813 10.6382 12.8853 10.0956 12.8853 9.42676V2.1582C12.8853 1.4894 12.3813 0.946777 11.7603 0.946777ZM2.01025 1.75439H11.7603C11.9665 1.75439 12.1353 1.93611 12.1353 2.1582V3.20306C11.622 3.66996 10.8884 4.31353 8.60557 6.26443C8.20947 6.60262 7.429 7.41781 6.88525 7.40771C6.3415 7.41781 5.55869 6.60262 5.16494 6.26443C2.88213 4.31353 2.14854 3.66996 1.63525 3.20306V2.1582C1.63525 1.93611 1.804 1.75439 2.01025 1.75439ZM11.7603 9.83057H2.01025C1.804 9.83057 1.63525 9.64885 1.63525 9.42676V4.25296C2.16963 4.72491 3.01338 5.45429 4.69853 6.89538C5.179 7.30929 6.02744 8.22038 6.88525 8.21533C7.73838 8.2229 8.57978 7.31938 9.07197 6.89538C10.7571 5.45429 11.6009 4.72491 12.1353 4.25296V9.42676C12.1353 9.64885 11.9665 9.83057 11.7603 9.83057Z"
              fill="#1E2027"
            />
          </svg>

          <span className="pl-4">info@utiva.io</span>
        </p>
      ),
    },
    {
      id: "p2",
      url: "tel:+2348035152982",
      linkName: (
        <p className="flex items-center">
          <svg
            width="10"
            height="15"
            viewBox="0 0 10 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.18525 2.82361V9.63971H3.58525V2.82361H7.18525ZM8.53525 0.097168H2.23525C1.48994 0.097168 0.885254 0.707777 0.885254 1.46039V13.275C0.885254 14.0276 1.48994 14.6382 2.23525 14.6382H8.53525C9.28057 14.6382 9.88525 14.0276 9.88525 13.275V1.46039C9.88525 0.707777 9.28057 0.097168 8.53525 0.097168ZM2.23525 13.7294C1.98775 13.7294 1.78525 13.5249 1.78525 13.275V1.46039C1.78525 1.21046 1.98775 1.00598 2.23525 1.00598H8.53525C8.78275 1.00598 8.98525 1.21046 8.98525 1.46039V13.275C8.98525 13.5249 8.78275 13.7294 8.53525 13.7294H2.23525ZM7.74775 1.91479H3.02275C2.83713 1.91479 2.68525 2.06816 2.68525 2.2556V10.2077C2.68525 10.3952 2.83713 10.5485 3.02275 10.5485H7.74775C7.93338 10.5485 8.08525 10.3952 8.08525 10.2077V2.2556C8.08525 2.06816 7.93338 1.91479 7.74775 1.91479ZM6.39775 11.9117H4.37275C4.18713 11.9117 4.03525 12.0651 4.03525 12.2525V12.4798C4.03525 12.6672 4.18713 12.8206 4.37275 12.8206H6.39775C6.58338 12.8206 6.73525 12.6672 6.73525 12.4798V12.2525C6.73525 12.0651 6.58338 11.9117 6.39775 11.9117Z"
              fill="#1E2027"
            />
          </svg>

          <span className="pl-4">+234 803 515 2982</span>
        </p>
      ),
    },
    {
      id: "p9",
      url: "tel:+447951828717",
      linkName: (
        <p className="flex items-center">
          <svg
            width="10"
            height="15"
            viewBox="0 0 10 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.18525 2.82361V9.63971H3.58525V2.82361H7.18525ZM8.53525 0.097168H2.23525C1.48994 0.097168 0.885254 0.707777 0.885254 1.46039V13.275C0.885254 14.0276 1.48994 14.6382 2.23525 14.6382H8.53525C9.28057 14.6382 9.88525 14.0276 9.88525 13.275V1.46039C9.88525 0.707777 9.28057 0.097168 8.53525 0.097168ZM2.23525 13.7294C1.98775 13.7294 1.78525 13.5249 1.78525 13.275V1.46039C1.78525 1.21046 1.98775 1.00598 2.23525 1.00598H8.53525C8.78275 1.00598 8.98525 1.21046 8.98525 1.46039V13.275C8.98525 13.5249 8.78275 13.7294 8.53525 13.7294H2.23525ZM7.74775 1.91479H3.02275C2.83713 1.91479 2.68525 2.06816 2.68525 2.2556V10.2077C2.68525 10.3952 2.83713 10.5485 3.02275 10.5485H7.74775C7.93338 10.5485 8.08525 10.3952 8.08525 10.2077V2.2556C8.08525 2.06816 7.93338 1.91479 7.74775 1.91479ZM6.39775 11.9117H4.37275C4.18713 11.9117 4.03525 12.0651 4.03525 12.2525V12.4798C4.03525 12.6672 4.18713 12.8206 4.37275 12.8206H6.39775C6.58338 12.8206 6.73525 12.6672 6.73525 12.4798V12.2525C6.73525 12.0651 6.58338 11.9117 6.39775 11.9117Z"
              fill="#1E2027"
            />
          </svg>

          <span className="pl-4">+44 795 182 8717</span>
        </p>
      ),
    },

    {
      id: "l3",
      url: "tel:012297777",
      linkName: (
        <p className="flex items-center">
          <svg
            width="10"
            height="15"
            viewBox="0 0 10 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.18525 2.82361V9.63971H3.58525V2.82361H7.18525ZM8.53525 0.097168H2.23525C1.48994 0.097168 0.885254 0.707777 0.885254 1.46039V13.275C0.885254 14.0276 1.48994 14.6382 2.23525 14.6382H8.53525C9.28057 14.6382 9.88525 14.0276 9.88525 13.275V1.46039C9.88525 0.707777 9.28057 0.097168 8.53525 0.097168ZM2.23525 13.7294C1.98775 13.7294 1.78525 13.5249 1.78525 13.275V1.46039C1.78525 1.21046 1.98775 1.00598 2.23525 1.00598H8.53525C8.78275 1.00598 8.98525 1.21046 8.98525 1.46039V13.275C8.98525 13.5249 8.78275 13.7294 8.53525 13.7294H2.23525ZM7.74775 1.91479H3.02275C2.83713 1.91479 2.68525 2.06816 2.68525 2.2556V10.2077C2.68525 10.3952 2.83713 10.5485 3.02275 10.5485H7.74775C7.93338 10.5485 8.08525 10.3952 8.08525 10.2077V2.2556C8.08525 2.06816 7.93338 1.91479 7.74775 1.91479ZM6.39775 11.9117H4.37275C4.18713 11.9117 4.03525 12.0651 4.03525 12.2525V12.4798C4.03525 12.6672 4.18713 12.8206 4.37275 12.8206H6.39775C6.58338 12.8206 6.73525 12.6672 6.73525 12.4798V12.2525C6.73525 12.0651 6.58338 11.9117 6.39775 11.9117Z"
              fill="#1E2027"
            />
          </svg>

          <span className="pl-4">01 229 7777</span>
        </p>
      ),
    },
  ];
  return (
    <>
      <footer className="pt-16 lg:pt-20 pb-20 footer">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between space-y-5 md:pr-9">
            <div className="max-w-full md:max-w-xs mt-5">
              <div className="mb-7">
                <img src={Logo} className="h-6 lg:h-8" alt="Logo" />
              </div>
              <div className="space-y-5 text-sm-15">
                <p className="text-default text-sm md:text-sm-15 leading-6">
                  We are the leading TECH EDUCATION company with students from
                  over 30 countries and a leading UK accreditation to deliver
                  the best digital workforce training.
                </p>
              </div>

              <SocialMedia />
            </div>
            <div className="w-full lg:w-8/12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 pt-10 lg:pt-0">
              <Column title="Top Courses" links={columnData1} />

              <Column title="Grow with Utiva" links={columnData2} />

              <Column title="Schools" links={columnData3} />

              <Column title="Company" links={columnData4} />

              <Column title="Resources" links={columnData5} />

              <Column title="Policies" links={columnData6} />

              <Column title="Contact" links={columnData7} isTargetLink={true} />
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 mt-12 lg:mt-16 text-center">
          <div className="pt-4 mb-5">
            <p className="text-default text-sm">
              © {new Date().getFullYear()} Utiva. All right reserved.
            </p>
          </div>
        </div>
      </footer>

      <FixedWhatsappLink />
    </>
  );
};

export default Footer;
