import React from "react";
import * as Animate from "react-reveal";

import EmailIcon from "../../../assets/images/svg/email.svg";
import CallIcon from "../../../assets/images/svg/phone.svg";
import ChatIcon from "../../../assets/images/svg/chat.svg";

const Option = ({ icon, title, desc, onClick, isLink, linkUrl }) => {
  return (
    <Animate.Fade bottom>
      <div className="mx-auto md:mx-0">
        {isLink ? (
          <a
            href={linkUrl}
            className="focus:outline-none inline-flex items-center justify-start"
          >
            <div className="py-4 px-4 bg-white rounded-lg mr-6 contact-us-option-icon">
              <img
                src={icon}
                className="h-8 w-8 object-contain"
                alt="enquiry"
              />
            </div>
            <p className="ml-5 lg:ml-0 text-default font-medium font-mono">
              {title}
            </p>
          </a>
        ) : (
          <button
            onClick={onClick}
            className="focus:outline-none inline-flex items-center"
          >
            <div className="py-4 px-4 bg-white rounded-lg mr-6 contact-us-option-icon">
              <img
                src={icon}
                className="h-8 w-8 object-contain"
                alt="enquiry"
              />
            </div>
            <p className="ml-5 lg:ml-0 text-default font-medium font-mono">
              {title}
            </p>
          </button>
        )}
        <p className="text-gray text-xs md:text-sm mt-4">{desc}</p>
      </div>
    </Animate.Fade>
  );
};

const ContactUsOptions = () => {
  return (
    <div className="pt-2 md:pt-6 pb-14  container mx-auto px-4">
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-10 relative">
        <Option
          isLink={true}
          linkUrl="mailto:info@utiva.io"
          icon={EmailIcon}
          title="Send an email"
          desc="Get response within 2 hours"
        />
        <Option
          isLink={true}
          linkUrl="tel:+2348035152982"
          icon={CallIcon}
          title="Call Us (Nigeria)"
          desc="Get response immediately"
        />
        <Option
          isLink={true}
          linkUrl="tel:+447495380293"
          icon={CallIcon}
          title="Call Us (Global)"
          desc="Get response immediately"
        />
        <Option
          isLink={true}
          linkUrl="https://wa.me/2348035152982"
          icon={ChatIcon}
          title="Chat with us"
          desc="Get response within 10 mins"
        />
      </div>
    </div>
  );
};

export default ContactUsOptions;
